document.addEventListener('turbolinks:load', () => {
  const $oldVersions = document.getElementsByClassName('is-old-version');
  const $checkbox = document.querySelector('input[name=show-old-versions]')

  var toggleOldVersions = function() {
    for (var i = 0; i < $oldVersions.length; ++i) {
      if ($checkbox.checked) {
        $oldVersions[i].style.display = 'block';
      } else {
        $oldVersions[i].style.display = 'none';
      }
    }
  };

  if ($checkbox) {    
    toggleOldVersions();
    $checkbox.addEventListener('change', toggleOldVersions);
  }
});
